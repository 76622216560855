<template>
	<div class="grid">
		<div class="col-12 md:col-6 lg:col-3">
			<div class="card overview-box flex flex-column pt-2">
				<div class="flex align-items-center muted-text">
					<i class="pi pi-shopping-cart"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Orders</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-xlarge">640</span>
						<span class="overview-status p-1 teal-bgcolor fs-small">1420 Completed</span>
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData1" type="line" :data="overviewChartData1" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-3">
			<div class="card overview-box flex flex-column pt-2">
				<div class="flex align-items-center muted-text">
					<i class="pi pi-dollar"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Revenue</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-xlarge">$57K</span>
						<span class="overview-status p-1 teal-bgcolor fs-small">$9,640 Income</span>
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData2" type="line" :data="overviewChartData2" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-3">
			<div class="card overview-box flex flex-column pt-2">
				<div class="flex align-items-center muted-text">
					<i class="pi pi-users"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Customers</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-xlarge">8572</span>
						<span class="overview-status p-1 pink-bgcolor fs-small">25402 Registered</span>
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData3" type="line" :data="overviewChartData3" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-3">
			<div class="card overview-box flex flex-column pt-2">
				<div class="flex align-items-center muted-text">
					<i class="pi pi-comments"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Comments</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-xlarge">805</span>
						<span class="overview-status p-1 teal-bgcolor fs-small">85 Responded</span>
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData4" type="line" :data="overviewChartData4" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import axios from 'axios'
export default {
	data() {
		return {
			message: [],
			customers: [],
			username:null,
			loader: false,
			email:null,
			count: 0,
			ingresoCostumer: false,
			items: [
					{label: 'Update', icon: 'pi pi-fw pi-refresh'},
					{label: 'Edit', icon: 'pi pi-fw pi-pencil'}
				],
		}
	},
	mounted(){
		// this.cargar_empresas();
	},
	methods:{
		cargar_empresas(){
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			this.loader = true;
			this.customers = [];
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			// console.log(cookie[cookieName]);
			// console.log(cookie[IdRol]);
			// console.log(this.$store.getters['infoNavigation/IdRol']);

			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];

			var _that = this;
			var url = 'cargar_empresas';
			window.api.call('post', url, {email: email,id_rol: id_rol}).then( function(response) {
				if(response.status == 200){
					_that.customers = response.data.data;
					// console.log(_that.customers);
					_that.loader = false;
				}
			}).catch(function (err) {
				console.log(err);
			});
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
	},
	computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		}
}
</script>

<style scoped>

</style>
